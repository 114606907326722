<template>
  <div class="outer-layout">
    <div class="outer-layout__page">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
.outer-layout {
  min-height: 100vh;

  background-color: $theme;

  .outer-layout__page {
    min-width: 1440px;
    min-height: 720px;
    // width: 1000px;
    // margin: 0 auto;
    // margin-top: 60px;
  }

  .style-setting {
    display: none;
  }
}
</style>
